import classNames from 'classnames';
import { Translate } from 'i18n';
import React from 'react';
import { useTypedDispatch } from 'students/stores/_utils';
import { modalActions } from 'students/stores/modal';
import { getAppPlatform } from 'students/utils';
import styled from 'styled-components';
import { styleInnerButton } from '../../components/ButtonGeneral/ButtonGeneral';
import UrlIcon from '../../components/UrlIcon';
import { customMediaQuery, SNoStyleGeneralButton } from '../../styled';
import crownIcon from './assets/crown_icon.svg';

type tProps = {
  className?: string;
};
const GetFullAccessButton: React.FC<tProps> = ({ className }) => {
  const dispatch = useTypedDispatch();
  function buttonClickHandler() {
    dispatch(modalActions.open('getLicenceModal'));
  }

  if (getAppPlatform() !== 'web') {
    return null;
  }

  return (
    <SWrapper className={classNames(className)}>
      <SButton onClick={buttonClickHandler}>
        <SUrlIcon url={crownIcon} height="20px" width="20px" color="#58311D" />
        {/* TODO add translation */}
        <Translate
          str="frontend.licences.get_full_access"
          params={{ defaultValue: 'Get full access' }}
        />
      </SButton>
    </SWrapper>
  );
};

export default GetFullAccessButton;

const SWrapper = styled.div`
  background: #020b2a;
  border-radius: 20px;
  padding: 12px;
  max-width: max-content;

  ${customMediaQuery('tablet')} {
    padding: 16px;
  }
`;

const SButton = styled(SNoStyleGeneralButton)`
  color: #58311d;
  border-radius: 10px;
  width: 100%;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 700;
  &,
  &:focus {
    background-color: #fbbc25;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #fbbc25dd;
  }

  ${styleInnerButton()} {
    padding: 10px;

    ${customMediaQuery('tablet')} {
      padding: 12px;
    }
  }
`;

const SUrlIcon = styled(UrlIcon)`
  margin-inline-end: 6px;
`;
